.MuiTabs-indicator {
    background: #757575 !important;
}

.slick-initialized {
    overflow: hidden !important
}

body {
    background: linear-gradient(to right, #ece9e6, #ffffff)
}


.MuiTab-wrapper {
    font-family: 'Bebas Neue' !important;
    letter-spacing: 1px;
    font-size: 1.3rem;
    color: #212121;
    text-decoration: none;
}



@keyframes kenburns {
    0% {
      transform-origin: bottom right;
      transform: scale(1.0);
      /* IE 9 */
    }
    100% {
      transform: scale(1.2);
    }
  }

@keyframes rotate {
    0% {
       transform: rotateY(0deg)
    }
    100% {
        transform: rotateY(-360deg)
    }
}

@media only screen and (max-width: 815px) {
    .MuiTab-wrapper {
        font-size: 1.05rem;
        line-height: 15px
    }
}